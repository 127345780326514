<template>
  <!-- Send token  -->
  <div class="send-token">
    <!-- Process -->
    <div class="process">
      <!-- Process-ul  -->
      <ul class="process-ul">
        <!-- Process-item  -->
        <li class="process-item">
          <img v-if="currentStep <= 3" :src="uploadBl" alt="Icon" />
          <img v-else :src="uploadGr" alt="Icon" />
          <span :class="{ 'process-title': true, active: currentStep <= 3 }">
            Upload
          </span>
        </li>
<!--         Bar-->
        <hr />
<!--         Process-item-->
        <li class="process-item">
          <img v-if="currentStep === 4" :src="qrcodeBl" alt="Icon" />
          <img v-else :src="qrcodeGr" alt="Icon" />
          <span :class="{ 'process-title': true, active: currentStep === 4 }">
            Generate
          </span>
        </li>
        <!-- Bar  -->
        <hr />
        <!-- Process-item  -->
        <li class="process-item">
          <img v-if="currentStep === 5" :src="storeBl" alt="Icon" />
          <img v-else :src="storeGr" alt="Icon" />
          <span :class="{ 'process-title': true, active: currentStep === 5 }">
            Store
          </span>
        </li>
      </ul>
      <div v-if="currentStep === 0" style="display: flex; justify-content: space-around;">
        <button @click="currentStep=1" class="download-btn">Input Private Key</button>
        <button @click="currentStep=2" class="download-btn">Input Mnemonic</button>
        <button @click="currentStep=3" class="download-btn">Upload Keystore File</button>
      </div>
      <div v-if="currentStep === 1" style="display: flex; justify-content: space-around;">
        <input class="input-field" placeholder="Private Key" v-model="privateKey"/>
        <input class="input-field" placeholder="Password" v-model="password"/>
        <button @click="generateFromPrivateKey" class="download-btn">Generate Keystore File</button>
      </div>
      <div v-if="currentStep === 2" style="display: flex; justify-content: space-around;">
        <input class="input-field" placeholder="Mnemonic" v-model="privateKey"/>
        <input class="input-field" placeholder="Password" v-model="password"/>
        <button @click="generateFromPassPhrase" class="download-btn">Generate Keystore File</button>
      </div>
      <div v-if="currentStep === 3" class="form-control">
        <!-- Label  -->
        <label>Upload Keystore file here</label>
        <!-- Drag & drop   -->
        <div id="drop_zone" @drop="dropHandler" @dragenter="checkDrop" @dragover="checkDrop">
          <label class="drag-drop" >
            <input  type="file" id="keystoreFile" name="keystoreFile" accept="text/keystore" @change="selectHandler">
            <!-- Span  -->
            <span>darg & drop file here</span>
            <p style="color: red" v-if="uploadError">{{uploadError}}</p>
          </label>
        </div>
      </div>
      <div v-else-if="currentStep === 4" ref="qrcode" style="display: flex; align-items: center">
        <vue-qrcode :value="keystoreFile" color="{ dark: '#000000ff', light: '#ffffffff' }" type="image/png"></vue-qrcode>
        <button @click="downloadLink" class="download-btn">Download</button>
      </div>
    </div>
  </div>
</template>

<script>
// Importing things we need
// import { ref } from "@vue/reactivity";
// import { useRouter } from "vue-router";
// Images
// import metaMaskGr from "../assets/images/metamask-gr.svg";


// import metaMask from "../assets/images/metamask.svg";
// import share from "../assets/images/share.svg";
// import upload from "../assets/images/upload.svg";

import qrcodeGr from "../assets/images/qr-code-gr.svg";
import qrcodeBl from "../assets/images/qr-code-bl.svg";
import storeBl from "../assets/images/safebox-bl.svg";
import storeGr from "../assets/images/safebox-gr.svg";
import uploadBl from "../assets/images/upload.svg";
import uploadGr from "../assets/images/upload-gr.svg";

// import QRCode from 'qrcode'
import {ethers} from "ethers";

// Components
import VueQrcode from 'vue-qrcode'
// import VueQRCodeComponent from 'vue-qrcode-component'
// import StepOne from "../components/form/StepOne.vue";
// import UploadKeystore from "../components/form/Upload.vue";
// import StepThree from "../components/form/StepThree.vue";
export default {
  name: "CreateQR",
  emits: ["setWallet"],
  components: { VueQrcode },
  // components: {UploadKeystore },
  data() {
    return {
      message: '',
      currentStep: 0,
      uploadError: '',
      keystoreFile: '',
      privateKey: '',
      password: '',
    };
  },
  methods: {
    downloadLink() {
      console.log(this.$refs.qrcode)
      let canvasImage = this.$refs.qrcode.getElementsByTagName('img')[0].src;
      let xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = function () {
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(xhr.response);
        a.download = 'qrcode.png';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        a.remove();
      };
      xhr.open('GET', canvasImage);
      xhr.send();
    },

    selectHandler(){
      let name = document.getElementById('keystoreFile');
      let reader = new FileReader();

      reader.onload = function (event) {
        console.log(event)
        this.keystoreFile = event.target.result.toString()
        this.currentStep = 4
      }.bind(this);
      reader.readAsText(name.files.item(0));
    },
    dropHandler(event) {
      event.stopPropagation();
      event.preventDefault()
      if(event.dataTransfer.files[0].type === "text/keystore"){
        let file = event.dataTransfer.files[0]
        let reader = new FileReader();

        reader.onload = function (event) {
          this.keystoreFile = event.target.result.toString()
          this.currentStep = 4;
        }.bind(this);
        reader.readAsText(file);
      }
    },
    checkDrop(e) {
      e.preventDefault()
    },
    async generateFromPrivateKey() {
      if(ethers.utils.isHexString(this.privateKey, 32)) {
        console.log(this.privateKey)
        let wallet = new ethers.Wallet(this.privateKey)
        this.keystoreFile = await wallet.encrypt(this.password)
        this.privateKey = ''
        this.password = ''
        this.currentStep = 4
      } else {
        console.log("Wrong Private Key")
        this.privateKey = ''
        this.password = ''
      }
      // ethers.Wallet.privateKey
    },
    async generateFromPassPhrase() {
      if(ethers.utils.isValidMnemonic(this.privateKey)) {
        console.log(this.privateKey)
        let wallet = ethers.Wallet.fromMnemonic(this.privateKey)
        this.keystoreFile = await wallet.encrypt(this.password)
        this.privateKey = ''
        this.password = ''
        this.currentStep = 4
      } else {
        console.log("Wrong Mnemonic")
        this.privateKey = ''
        this.password = ''
      }
      // ethers.Wallet.privateKey
    },
  },
  setup() {
    // Returning data
    return { qrcodeGr, qrcodeBl , storeGr, storeBl, uploadGr, uploadBl };
  },
};
</script>

<style lang="scss" scoped>
// send-token styles
.send-token {
  // process styles
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  margin: 10px;
  position: relative;
  .process {
    max-width: 1000px;
    margin: auto;
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    min-height: 500px;
    width: 100%;
    // process-ul
    .process-ul {
      display: flex;
      align-items: center;
      list-style: none;
      justify-content: space-evenly;
      padding: 25px;
      list-style: none;
      gap: 10px;
      // process-item
      .process-item {
        text-align: center;
        display: block;
        width: 100%;
        .process-title {
          font-weight: bold;
          padding: 10px 0px;
          font-size: 1.2rem;
          color: rgb(173, 169, 169);
        }
        span {
          font-size: 1rem;
          text-transform: capitalize;
          color: #000;
          display: block;
          text-align: center;
        }
        img {
          width: 20%;
        }
      }
      hr {
        width: 100%;
        background: transparent linear-gradient(90deg, #9e0702 0%, #a04e4b 100%)
          0% 0% no-repeat padding-box;
        height: 3px;
        border: 0px;
      }
    }
  }
  #qrcode {
    width:160px;
    height:160px;
    margin-top:15px;
  }
  .input-field {
    padding: 20px;
    margin-top: 45px;
    height: 30px;
    width: 250px;
  }
  .download-btn {
    background: transparent linear-gradient(90deg, #9e0702 0%, #a04e4b 100%);
    padding: 10px 20px;
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: capitalize;
    color: #fff;
    border: 0px;
    border-radius: 15px;
    cursor: pointer;
    margin: 40px;
  }
  // drag-drop styles
  .drag-drop {
    display: grid;
    place-items: center;
    padding: 20px;
    border: 1px solid #222;
    border-radius: 5px;
    min-height: 200px;
    // span styles
    width: 100%;
    span {
      text-transform: capitalize;
      font-size: 1.2rem;
      font-weight: bold;
    }
    input {
      display: none;
    }
  }

  .success-modal {
    display: grid;
    place-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    .wraper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #fff;
      padding: 20px;
      min-width: 250px;
      min-height: 300px;
      max-width: 400px;
      border: 1px solid #000;
      margin: 10px;
      border-radius: 10px;
      .heading,
      .sub-heading {
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
        padding: 10px;
      }
      .see-btn {
        background: #30d0a5;
        padding: 10px 20px;
        font-size: 1.2rem;
        font-weight: bold;
        text-transform: capitalize;
        color: #fff;
        border: 0px;
        border-radius: 15px;
        cursor: pointer;
        margin-top: 40px;
      }
    }
  }
}

//@media only screen and(max-width:640px) {
//  // send-token styles
//  .send-token {
//    // process styles
//    .process {
//      padding: 20px 0px;
//
//      // process-ul
//      .process-ul {
//        display: flex;
//        align-items: center;
//        list-style: none;
//        justify-content: space-evenly;
//        padding: 10px;
//        list-style: none;
//        // process-item
//        .process-item {
//          img {
//            width: 30px;
//          }
//        }
//      }
//    }
//  }
//}

.active {
  color: #000 !important;
}
</style>
