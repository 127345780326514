import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import CreateQR from "@/views/CreateQR";

const routes = [{
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/createqr",
        name: "CreateQR",
        component: CreateQR,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;